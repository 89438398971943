import { QueryClient, QueryKey, UseMutationOptions, UseQueryOptions, WithRequired, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { ScoreGroupSetting } from '../@types';
import { getAllScoreGroupSettings, getScoreGroupSettings, postScoreGroupSettings } from './score-group-settings.api';

export const scoreGroupSettingsKeys = {
  all: (): QueryKey => ['AllScoreGroupSetting'],
  allByScoreGroupId: (scoreGroupId: string): QueryKey => [...scoreGroupSettingsKeys.all(), scoreGroupId],
  detail: (scoreGroupId: string, locationId?: string): QueryKey => [...scoreGroupSettingsKeys.all(), scoreGroupId, locationId],
};
export const allScoreGroupSettingsQuery = (
  scoreGroupId: string,
  locationId?: string,
  signal?: AbortSignal
): WithRequired<UseQueryOptions<ScoreGroupSetting>, 'queryKey'> => ({
  queryKey: scoreGroupSettingsKeys.allByScoreGroupId(scoreGroupId),
  queryFn: ({ signal: querySignal }) => getAllScoreGroupSettings(scoreGroupId, locationId, signal || querySignal),
});

export const scoreGroupSettingsQuery = (
  scoreGroupId: string,
  locationId?: string,
  signal?: AbortSignal
): WithRequired<UseQueryOptions<ScoreGroupSetting>, 'queryKey'> => ({
  queryKey: scoreGroupSettingsKeys.detail(scoreGroupId, locationId),
  queryFn: ({ signal: querySignal }) => getScoreGroupSettings(scoreGroupId, locationId, signal || querySignal),
});

export const scoreGroupSettingsMutation = (
  queryClient: QueryClient,
  scoreGroupId: string,
  locationId?: string
): UseMutationOptions<ScoreGroupSetting, any, ScoreGroupSetting, any> => ({
  mutationKey: scoreGroupSettingsKeys.detail(scoreGroupId, locationId),
  mutationFn: scoreGroupSettings => postScoreGroupSettings(scoreGroupId, locationId, { ...scoreGroupSettings }),
  onMutate: async scoreGroupSettings => {
    // Verwijderen van vorige mutations die in de cache zitten, met dezelfde key en nog niet uitgevoerd zijn. We willen alleen de laatste uitvoeren.
    // Onderstaande lijkt niet mogelijk
    // const mutationCache = queryClient.getMutationCache();
    // const sameKeyMutations = queryClient.getMutationCache().findAll({
    //   mutationKey: scoreGroupKeys.detail(id),
    //   exact: true,
    //   fetching: true,
    // });
    // if (sameKeyMutations.length > 1) {
    //   const highestId = Math.max(...sameKeyMutations.map(mutation => mutation.mutationId));

    //   sameKeyMutations.forEach(mutation => {
    //     if (mutation.mutationId !== highestId) {
    //       mutationCache.remove(mutation);
    //     }
    //   });
    // }

    // Cancel current queries for the user
    await queryClient.cancelQueries({ queryKey: scoreGroupSettingsKeys.detail(scoreGroupId, locationId) });
    const previousData = queryClient.getQueryData(scoreGroupSettingsKeys.detail(scoreGroupId, locationId));

    // Optimistic edit of the user
    queryClient.setQueryData(scoreGroupSettingsKeys.detail(scoreGroupId, locationId), () => scoreGroupSettings);

    // Return context with the optimistic todo
    return { optimisticData: scoreGroupSettings, previousData };
  },
  onSuccess: (result, _scoreGroupSettings, _context) => {
    // Replace optimistic user with the actual result (todo: if result is precisely the same as context.optimisticData, then don't replace and don't trigger rerender)
    // queryClient.setQueryData(scoreGroupSettingsKeys.detail(scoreGroupId, locationId), () => result);
    message.success('Scoregroep instelling is opgeslagen');
  },
  onError: (error, _scoreGroupSettings, context) => {
    message.error(error);
    // Reset ScoreGroupSettings to previousData
    queryClient.setQueryData(scoreGroupSettingsKeys.detail(scoreGroupId, locationId), () => context.previousData);
  },
  onSettled: () => {
    queryClient.invalidateQueries({ queryKey: scoreGroupSettingsKeys.detail(scoreGroupId, locationId) });
    queryClient.invalidateQueries(['AllScoreGroupSetting', scoreGroupId]);
  },
});

// This function is callable from anywhere and it enables you to already have the AllScoreGroupSettings available in cache
export const prefetchAllScoreGroupSettings = async (queryClient: QueryClient, scoreGroupId: string, locationId?: string): Promise<void> => {
  await queryClient.prefetchQuery(allScoreGroupSettingsQuery(scoreGroupId, locationId));
};

// This function is callable from anywhere and it enables you to already have the ScoreGroupSettings available in cache
export const prefetchScoreGroupSettings = async (queryClient: QueryClient, scoreGroupId: string, locationId?: string): Promise<void> => {
  await queryClient.prefetchQuery(scoreGroupSettingsQuery(scoreGroupId, locationId));
};

export const useAllScoreGroupSettings = (scoreGroupId: string, locationId: string) => {
  const query = useQuery(allScoreGroupSettingsQuery(scoreGroupId, locationId));
  return { query };
};

export const useScoreGroupSettings = (scoreGroupId: string, locationId?: string) => {
  const queryClient = useQueryClient();
  const query = useQuery(scoreGroupSettingsQuery(scoreGroupId, locationId));
  const update = useMutation(scoreGroupSettingsMutation(queryClient, scoreGroupId, locationId));
  return { query, update };
};
